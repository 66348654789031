<script setup>
import texts from "~/configs/texts";

const props = defineProps({
  offerUuid: String,
})

const agreementFilePath = "/media/pdf/deposit_statute.pdf";
const agreementHTML = computed(() => texts.payment.form.agreement.title.replace(/\[([^\]]+)\]/, `<a href='${agreementFilePath}' target='_blank' class='underline'>$1</a>`));
const agreementContentHTML = computed(() => texts.payment.form.agreement.content.replace(/\[([^\]]+)\]/, `<a href='${agreementFilePath}' target='_blank' class='underline'>$1</a>`));

const refStateBlend = ref();
const expand = ref(false);
const isOpened = ref(false);
const generalError = ref("");
const errors = ref({
  name: null,
  lastname: null,
  phone: null,
  email: null,
});

const values = ref({
  name: null,
  lastname: null,
  phone: null,
  email: null,
  agreement: null,
});

const submit = async () => {
  errors.value = {
    name: null,
    lastname: null,
    phone: null,
    email: null,
  }

  generalError.value = "";
  let passed = true;

  if (!values.value.name || values.value.name.length < 2) {
    errors.value.name = "Ta wartość jest niepoprawna";
    passed = false;
  }

  if (!values.value.lastname || values.value.lastname.length < 2) {
    errors.value.lastname = "Ta wartość jest niepoprawna";
    passed = false;
  }

  if (!values.value.phone || !validatePhone(values.value.phone)) {
    errors.value.phone = "Wprowadź poprawny numer telefonu";
    passed = false;
  }

  if (!values.value.email || !validateEmail(values.value.email)) {
    errors.value.email = "Wprowadź poprawny adres email";
    passed = false;
  }

  if (!values.value.agreement) {
    errors.value.agreement = "To pole jest wymagane";
    passed = false;
  }

  if (passed) {
    refStateBlend.value.setBusy();
    const response = await useBackend().createPaymentLink(props.offerUuid, {
      phone_number: values.value.phone,
      email: values.value.email,
      name: values.value.name,
      surname: values.value.lastname,
    });
    refStateBlend.value.setNone();

    if(response?.status !== 200) {
      generalError.value = "Wystąpił błąd podczas tworzenia płatności. Spróbuj za chwilę ponownie.";
      return;
    }


    if(response?.data?.payment_url) {
      try {
        await downloadURI(agreementFilePath,'VEHIS_regulamin_kaucji.pdf');
      } catch (error) {
        generalError.value = error.message;
        return;
      }
      window.location.href = response.data.payment_url;
    }
  }
}

const downloadURI = async (url, name) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(res => {
        if (!res.ok) {
          return reject(new Error("Nie można rozpocząć procesu płatności. Spróbuj ponownie."))
        }
        return res.blob()
      })
      .then(blob => {
        let fileUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileUrl);
        a.remove();
        resolve();
      })
      .catch((error) => {
        reject(new Error("Nie można pobrać regulaminu. Spróbuj ponownie"))
      })
  })
}

const openPopup = () => {
  isOpened.value = true;
}

const closePopup = () => {
  isOpened.value = false;
}

defineExpose({
  openPopup,
  closePopup,
})

</script>

<template>
  <PopupModal :open-popup="isOpened" @update:open-popup="closePopup">
    <FormStateBlend ref="refStateBlend" />
    <div class="max-w-3xl relative">
      <div class="text-2xl font-light">{{ texts.payment.form.title }}</div>
      <div class="grid grid-cols-1 md:grid-cols-4 mt-1 gap-4">
        <InputText label="Imię" :error="errors.name" v-model="values.name" />
        <InputText label="Nazwisko" :error="errors.lastname" v-model="values.lastname" />
        <InputText label="Numer telefonu" :error="errors.phone" v-model="values.phone" />
        <InputText label="Adres email" :error="errors.email" v-model="values.email" />
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
        <div>
          <InputCheckBox @change="(checked) => values.agreement = checked" :error="errors.agreement">
            <span class="pr-1" v-html='agreementHTML'></span>
          </InputCheckBox>
        </div>
        <ButtonRounded color="celadon" @click="submit">Rezerwuję z obowiązkiem zapłaty</ButtonRounded>
      </div>
      <div class="text-xs font-extralight pt-5 text-gray-400" v-html="agreementContentHTML"></div>
      <div class="rounded-md bg-red-600 mt-5 text-white p-2 text-sm" v-show="generalError">{{ generalError }}</div>
    </div>
  </PopupModal>
</template>
